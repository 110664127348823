<template>
  <div class="list-page">
    <list-layout ref="listLayout"
                 :filter-form="filterForm" :thead="sthead" :tab-arr="tabArr"
                 :on-fetch="getBrandList" :on-update="changeStatus"
                 :on-delete="handleDelete" :on-put-back="handlePutBack"
                 :on-edit="editRow"
                 @command="handleListOperation">
      <template v-slot:top>
        <el-button class="top-btn" type="primary" size="small" @click="handleAddBrand">新增</el-button>
      </template>
      <template v-slot:filter>
        <list-filter :filterForm="filterForm" :pidList="[]" :uploadFilter="ok" />
      </template>
      <template v-slot:item_activity_count="{row}">
        <!-- 添加活动和文章 -->
        <p>
          <span v-if="filterForm.tab === 'normal'" class="el-icon-remove-outline cursor-pointer" @click="handleReduce(row, 'activity_count')" />
          &nbsp;{{ row.activity_count | placeholder }}&nbsp;
          <span v-if="filterForm.tab === 'normal'" class="el-icon-circle-plus-outline cursor-pointer" @click="handleAdd(row, 'activity_count')" />
        </p>
      </template>
      <template v-slot:item_articles_count="{row}">
        <!-- 添加活动和文章 -->
        <p>
          <span v-if="filterForm.tab === 'normal'" class="el-icon-remove-outline cursor-pointer" @click="handleReduce(row, 'articles_count')" />
          &nbsp;{{ row.articles_count | placeholder }}&nbsp;
          <span v-if="filterForm.tab === 'normal'" class="el-icon-circle-plus-outline cursor-pointer" @click="handleAdd(row, 'articles_count')" />
        </p>
      </template>
      <template v-slot:operation="scope">
        <!-- <el-button size="small" @click="checkRow(scope.row)" type="text">查看</el-button> -->
        <el-button size="small" @click="editRow(scope.row)" type="text">编辑</el-button>
      </template>
      <ArticleActivitySelector v-model="openHandleList" :selectData="selectData" :upload="refreshList" />
    </list-layout>
  </div>
</template>

<script>
import listFilter from "../components/ListFilter";
import ArticleActivitySelector from "../components/ArticleActivitySelector.vue";
import {
  brandList,
  updateSort,
  softDelete,
  Delete,
  putBack,
} from "../api/brand-list";
import ListLayout from "@/base/layout/ListLayout";
import Reminder from "@/modules/common/components/Reminder";
export default {
  data() {
    return {
      tabArr: [
        { label: "全部", name: "normal" },
        { label: "回收站", name: "deleted" },
      ],
      thead: [
        { label: '', type: 'selection'},
        { label: "ID", prop: "id" },
        { label: "组织名称", prop: "name", minWidth: 150 },
        {
          label: "负责人数",
          prop: "hosts_count",
          minWidth: 120,
          sortable: true,
        },
        {
          label: "活动数",
          prop: "activity_count",
          minWidth: 120,
          sortable: true,
          type: 'slot'
        },
        {
          label: "文章数",
          prop: "articles_count",
          minWidth: 120,
          sortable: true,
          type: 'slot'
        },
        {
          label: "收藏数",
          prop: "collect_count",
          minWidth: 120,
          sortable: true,
        },
        {
          label: "创建时间",
          prop: "create_time",
          minWidth: 200,
          sortable: true,
        },
        { label: "排序", prop: "sort" },
        {label: "操作", type: 'operation', width: 100, operations: [
            // {command: 'detail', text: '查看', visible: (row, form) => form.tab !== 'deleted'},
            {command: 'edit', visible: (row, form) => form.tab !== 'deleted'},
            {command: 'putback', visible: (row, form) => form.tab === 'deleted'},
            {command: 'delete'},
          ]}
      ],
      schoolThead: [
        { label: '', type: 'selection'},
        { label: "ID", prop: "id" },
        { label: "校友会名称", prop: "name", minWidth: 150 },
        {
          label: "负责人数",
          prop: "hosts_count",
          minWidth: 120,
          sortable: true,
        },
        {
          label: "活动数",
          prop: "activity_count",
          minWidth: 120,
          sortable: true,
          type: 'slot'
        },
        {
          label: "文章数",
          prop: "articles_count",
          minWidth: 120,
          sortable: true,
          type: 'slot'
        },
        {
          label: "收藏数",
          prop: "collect_count",
          minWidth: 120,
          sortable: true,
        },
        {
          label: "创建时间",
          prop: "create_time",
          minWidth: 200,
          sortable: true,
        },
        { label: "排序", prop: "sort" },
        {label: "操作", type: 'operation', width: 100, operations: [
            // {command: 'detail', text: '查看', visible: (row, form) => form.tab !== 'deleted'},
            {command: 'edit', visible: (row, form) => form.tab !== 'deleted'},
            {command: 'putback', visible: (row, form) => form.tab === 'deleted'},
            {command: 'delete'},
          ]}
      ],
      // 筛选对象
      filterForm: {
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        keyword: "", //组织名称
        // pid: -2, //所属组织
        tab: "normal", //当前筛选tab
        page_size: 15, //每页多少条数据
      },
      selectData: {
        id: 0, // 品牌Id
        mark: 0, // 添加或删除
        status: 0, //添加或删除的类型
      },
      openHandleList: false, // 列表操作弹窗
    };
  },
  methods: {
    handleListOperation({command, row}) {
      // if (command === 'detail') {
      //   this.checkRow(row)
      // }
    },
    // 获取品牌列表
    getBrandList(pageData) {
      return brandList(pageData)
    },
    // 新增品牌
    handleAddBrand() {
      // this.$router.push({
      //   name: "addBrand",
      //   params: { id: 0, type: "add" },
      // });
      let routerData = this.$router.resolve({
        name: "addLevel",
        params: { id: 0, type: "add" },
      });
      window.open(routerData.href, "_blank");
    },
    // 新增活动文章
    handleAdd(row, key) {
      this.selectData.id = row.id;
      this.selectData.mark = 0;
      this.selectData.status = key === "articles_count" ? 1 : 0;
      this.openHandleList = true;
    },
    // 减少活动文章
    handleReduce(row, key) {
      this.selectData.id = row.id;
      this.selectData.mark = 1;
      this.selectData.status = key === "articles_count" ? 1 : 0;
      this.openHandleList = true;
    },
    // 数据删除
    handleDelete(idList, forever = false) {
      return forever ? Delete(idList) : softDelete(idList)
    },
    // 点击列表还原
    handlePutBack(data) {
      return putBack(data)
    },
    // 点击编辑
    editRow(row) {
      this.$router.push({
        name: "editLevel",
        params: { id: row.id, type: "edit" },
      });
    },
    // 点击查看
    checkRow(row) {
      this.$router.push({ name: "checkLevel", params: { id: row.id } });
    },
    // 筛选回调
    ok(e) {
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.$refs.listLayout.getList(this.filterForm)
    },
    // 刷新列表
    refreshList() {
      this.$refs.listLayout.getList()
    },
    // 修改列表相关状态
    changeStatus(data) {
      return updateSort(data)
    },

  },
  components: {
    Reminder,
    ListLayout,
    listFilter,
    ArticleActivitySelector,
  },
  computed: {
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
    sthead() {
      return this.tenantVersion === 'school' ?  this.schoolThead: this.thead
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  .body {
    .err-box {
      ::v-deep.el-input__inner {
        color: red;
      }
    }
  }
}
</style>
