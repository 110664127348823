<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="false">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from">

      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item :label-width="tenantVersion === 'school' ? '80px':'70px'" :label="tenantVersion === 'school' ? '校友会名称：':'组织名称：'" prop="keyword">
          <el-input  v-model="form.keyword" placeholder="请输入"></el-input>
        </el-form-item>
        <!-- <el-form-item label="所属组织：" prop="pid">
          <el-select v-model="pid" placeholder="请选择组织">
            <el-option v-for="item in pidList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item> -->
      </div>
      <div class="filter-item">
        <DatePeriodPicker label="创建时间：" start-prop="start_time" :start-time.sync="form.start_time"
          end-prop="end_time" :end-time.sync="form.end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
export default {
  name: "multi-level-list-layout",
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    pidList: {
      type: Array,
    },
    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      rules: {},
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {},
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
    // pid: {
    //   get() {
    //     return this.form.pid == -2 ? "" : this.form.pid;
    //   },
    //   set(val) {
    //     this.form.pid = val;
    //   },
    // },
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
